import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PostWrapper from "../../components/postWrapper"
import PostList from "../../components/postList"
import PostSidebar from "../../components/postSidebarFilter"
import Pagination from "../../components/pagination"

export const query = graphql`
  query ($slug: String, $skip: Int, $limit: Int) {
    allSanityCaseStudy(filter: {category: {slug: {current: {eq: $slug}}}}, sort: {fields: publishDate, order: DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
          id
          title
          slug { current }
          category {
            title
            slug { current }
          }
          _rawThumbnail(resolveReferences: {maxDepth: 10})
        }
      }
    }
  }
`

const CaseStudyCategory = (props) => {
  const caseStudies = props.data.allSanityCaseStudy.edges
  const { currentPage, totalPages, slug } = props.pageContext

  return (
    <Layout theme="light">
      <SEO title="Watershed Case Studies" />
      <Hero heroTitle="Watershed Case Studies" theme="light" graph="radar" />
      <PostWrapper>
        <PostList items={caseStudies} theme="brandDelta" type="case-studies" alt />
        <PostSidebar theme="brandDelta" type="caseStudies" />
      </PostWrapper>
      {totalPages > 1 && <Pagination base={`case-studies/${slug}/`} currentPage={currentPage} totalPages={totalPages} />}
    </Layout>
  )
}

export default CaseStudyCategory
